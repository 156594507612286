<script lang="ts" setup>
import { scrollToHash } from '~/assets/js/util'

definePageMeta({
  layout: false,
})

let initialRenderStore = useInitialRenderStore()
let route = useRoute()

let { data: site, pending: sitePending } = await useWordpressData('wp-remote/site')
let {
  data,
  pending: dataPending,
  error,
} = await useWordpressData(`wp-remote/page/${route.params.slug || '_'}`)
let pending = logicOr(sitePending, dataPending)

watch(
  error,
  value => {
    if (value) {
      showError(value)

      // We don't want to fail silently in production
      if (import.meta.env.MODE === 'production') {
        throw value
      }
    } else {
      clearError()
    }
  },
  { immediate: true },
)

if (String(error.value).includes('404')) {
  throw createError({ statusCode: 404, statusMessage: 'Page Not Found' })
}

useSEO(site, data)

until(() => !pending.value && Boolean(data.value))
  .toBe(true)
  .then(() => {
    scrollToHash()
  })
</script>

<template>
  <div>
    <Layout v-if="!pending && data" :name="data.template">
      <WpceDocument :tree="data.document" />
    </Layout>
    <Transition name="fade">
      <LoadingOverlay v-if="initialRenderStore.isInitial && pending" />
    </Transition>
  </div>
</template>

<style lang="scss" scoped></style>
